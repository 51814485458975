<template>
  <div class="article_container" @click.stop="closeSelect">
    <new-nav
      ref="nav"
      :page="'Article'"
      v-if="hideNav"
      :detailUrl="objUrl"
      :typeList="typeList"
    ></new-nav>
    <div class="container">
      <h2 class="headline">{{ content.novel_name }}</h2>
      <h3 class="chapter_name ta_a">{{ content.chapter_name }}</h3>
      <!-- 章节列表 -->
      <div class="chapter-nav">
        <div class="btn-group">
          <div
            class="prev"
            :class="{ disable_class: !content.prev_chapter_id }"
            @click="onPrev(content.prev_chapter_id)"
          >
            <p class="cutL"></p>
            <h3>Prev Chapter</h3>
          </div>
          <div
            class="list"
            :class="{ w_c: chapterShow }"
            @click="onChapterSelect"
          >
            <img :src="img.selectIcon" v-if="!chapterShow" />
            <chapter-select
              class="chapter-select"
              v-if="chapterShow"
              :list="chapterList"
              :search="chapterSelect"
              :showSelect="showSelect"
              @changes="changeType"
            ></chapter-select>
          </div>
          <div
            class="next"
            :class="{ disable_class: !content.next_chapter_id }"
            @click="onNext(content.next_chapter_id)"
          >
            <h3>Next Chapter</h3>
            <p class="cutR"></p>
          </div>
        </div>
        <div class="hide_nav" @click="hideNav = !hideNav">
          <img :src="img.cutT" v-show="hideNav" />
          <img :src="img.cutB" v-show="!hideNav" />
        </div>
      </div>
      <div class="txt" v-html="content.content"></div>
      <div class="bottom_select">
        <div class="btn-group t_a">
          <div
            class="prev"
            :class="{ disable_class: !content.prev_chapter_id }"
            @click="onPrev(content.prev_chapter_id)"
          >
            <p class="cutL"></p>
            <h3>Prev Chapter</h3>
          </div>
          <div
            class="list"
            :class="{ w_c: chapterShow }"
            @click="onChapterSelect"
          >
            <img :src="img.selectIcon" v-if="!chapterShow" />
            <chapter-select
              class="chapter-select chapter-t"
              v-if="chapterShow"
              :list="chapterList"
              :search="chapterSelect"
              :showSelect="showSelect"
              @changes="changeType"
            ></chapter-select>
          </div>
          <div
            class="next"
            :class="{ disable_class: !content.next_chapter_id }"
            @click="onNext(content.next_chapter_id)"
          >
            <h3>Next Chapter</h3>
            <p class="cutR"></p>
          </div>
        </div>
        <!-- <div class="btn">wwww</div> -->
        <!-- <div class="hint">
          Tip: You can use left, right, A and D keyboard keys to browse between
          chapters.
        </div> -->
      </div>
    </div>
    <new-footer></new-footer>
  </div>
</template>

<script>
import {
  fictionChapterDetail,
  fictionChapterList,
  fictionLabel,
} from "@/api/index";
import cutT from "@/assets/img/cutT.png";
import cutB from "@/assets/img/cutB.png";
import selectIcon from "@/assets/img/selectIcon.png";
import NewNav from "@/components/pc/NewNav.vue";
import NewFooter from "@/components/pc/NewFooter.vue";
import ChapterSelect from "@/components/ChapterSelect.vue";
export default {
  components: { NewNav, NewFooter, ChapterSelect },
  name: "Article",
  metaInfo() {
    return {
      title:
        this.content.novel_name +
        "-" +
        this.content.chapter_name +
        " | Webnovelsite",
      meta: [
        {
          name: "title",
          content:
            this.content.novel_name +
            "-" +
            this.content.chapter_name +
            " | Webnovelsite",
        },
        {
          name: "description",
          content: `Read free webnovel update - ${this.content.novel_name} - ${this.content.chapter_name} | Webnovelsite`,
        },
        {
          name: "keywords",
          content: "",
        },
      ],
    };
  },
  data() {
    return {
      img: {
        cutT: cutT,
        cutB: cutB,
        selectIcon: selectIcon,
      },
      hideNav: true, //   导航条显示隐藏
      content: { novel_name: "", chapter_name: "" }, //内容
      chapterList: [], //章节列表
      chapterSelect: "", //选中章节
      showSelect: false, //选择框是否展开
      chapterShow: false, //是否显示章节选项
      nid: null, //小说id
      cid: null, //章节id
      objUrl: {},
      typeList: [], //小说类型
    };
  },
  inject: ["reload"],
  created() {
    this.nid = this.$route.params.nid;
    this.cid = this.$route.params.cid;
    this.getChapterDetail();
    let typeList = this.$local.cacheGet("typeList");
    if (!typeList) {
      this.getTypeList();
    } else {
      this.typeList = typeList;
    }
  },
  methods: {
    // 小说类型
    getTypeList() {
      fictionLabel().then((res) => {
        if (res.code == 200) {
          this.typeList = res.data;
          let time = 24 * 60 * 60;
          this.$local.cacheSet("typeList", res.data, time);
        }
      });
    },
    // 获取章节内容
    getChapterDetail() {
      fictionChapterDetail(this.nid, this.cid).then((res) => {
        if (res.code == 200) {
          this.content = res.data;
          this.chapterSelect = res.data.chapter_name;
          this.objUrl = {
            id: this.nid,
            name: res.data.novel_name,
            detailName: this.chapterSelect,
          };
          this.localstoryList();
        }
      });
    },
    // 获取小说章节列表接口
    getFictionChapterList() {
      fictionChapterList(1, 10000, this.nid).then((res) => {
        if (res.code == 200) {
          this.chapterList = res.data.data;
        }
      });
    },
    // 关闭选择框
    closeSelect() {
      if (this.hideNav) {
        this.$refs.nav.clearSelect();
      }
    },
    // 章节显示
    onChapterSelect() {
      if (!this.chapterShow) {
        this.chapterShow = true;
        this.getFictionChapterList();
        // let timer = setTimeout(function () {
        //   if (timer) {
        //     clearTimeout(this.timeHandle);
        //   }
        //   this.chapterShow = true;
        // }, 1000);
      } else {
        this.showSelect = !this.showSelect;
      }
    },
    // 切换章节
    changeType(name, id) {
      if (id == this.cid) {
        return;
      }
      this.chapterSelect = name;
      this.cid = id;
      this.$router.push({
        path: `/article/${this.nid}/${this.cid}`,
      });
      this.reload();
    },
    // 去上一页
    onPrev(prevId) {
      if (prevId) {
        this.cid = prevId;
        this.$router.push(`/article/${this.nid}/${this.cid}`);
        this.reload();
      }
    },
    // 去下一页
    onNext(nextId) {
      if (nextId) {
        this.cid = nextId;
        this.$router.push(`/article/${this.nid}/${this.cid}`);
        this.reload();
      }
    },
    // 历史保存
    localstoryList() {
      let arrayLocal = JSON.parse(localStorage.getItem("historyList"));
      if (arrayLocal) {
        let isTrue = arrayLocal.some((item) => item.novel_id == this.nid);
        if (isTrue) {
          arrayLocal.forEach((item) => {
            if (item.novel_id == this.nid) {
              (item.last_chapter_name = this.content.chapter_name),
                (item.chapter_id = this.cid);
            }
          });
        } else {
          arrayLocal.unshift({
            novel_id: this.nid,
            novel_name: this.content.novel_name,
            last_chapter_name: this.content.chapter_name,
            chapter_id: this.cid,
          });
        }
      } else {
        arrayLocal = [];
        arrayLocal.unshift({
          novel_id: this.nid,
          novel_name: this.content.novel_name,
          last_chapter_name: this.content.chapter_name,
          chapter_id: this.cid,
        });
      }

      localStorage.setItem("historyList", JSON.stringify(arrayLocal));
    },
  },
};
</script>

<style lang="scss" scoped>
.article_container {
  .ta_a {
    text-align: center;
  }

  .headline {
    color: #690;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 5px;
    text-align: center;
  }

  .chapter_name {
    font-size: 18px;
    color: #2b2b2b;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .chapter-nav {
    text-align: center;
    position: relative;
    margin-bottom: 20px;

    .hide_nav {
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 6px;
      padding: 10px 16px;
      background-color: #5cb85c;

      img {
        display: block;
        width: 18px;
        height: 18px;
      }
    }
  }

  .btn-group {
    color: #fff;
    display: flex;
    justify-content: center;

    div {
      line-height: 22px;
      background-color: #5cb85c;
      cursor: pointer;
    }

    .prev {
      padding: 6px 12px 6px 26px;
      width: 134px;
      border-bottom-left-radius: 4px;
      border-top-left-radius: 4px;
      display: flex;
      align-items: center;
      .cutL {
        position: relative;

        &::after {
          content: " ";
          position: absolute;
          left: -14px;
          top: 50%;
          transform: translate(0, -50%);
          content: " ";
          background: url("~@/assets/img/cutL.png") center center no-repeat;
          background-size: 100% 100%;
          width: 14px;
          height: 14px;
        }
      }
    }

    .list {
      padding: 6px 12px;
      margin: 0 5px;

      img {
        display: inline-block;
        vertical-align: middle;
        width: 18px;
        height: 18px;
      }
      .chapter-select {
        position: relative;
        &::after {
          position: absolute;
          right: -8px;
          top: 50%;
          transform: translate(0, -50%);
          content: " ";
          background: url("~@/assets/img/cutB.png") center center no-repeat;
          background-size: 100% 100%;
          width: 10px;
          height: 10px;
        }
      }
      .chapter-t {
        &::v-deep .select-content {
          top: unset;
          bottom: 28px;
        }
      }
    }
    .w_c {
      width: 165px;
    }

    .next {
      width: 134px;
      padding: 6px 26px 6px 12px;
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px;
      display: flex;
      align-items: center;
      .cutR {
        position: relative;

        &::after {
          content: " ";
          position: absolute;
          left: 0;
          top: 50%;
          transform: translate(0, -50%);
          content: " ";
          background: url("~@/assets/img/cutR.png") center center no-repeat;
          background-size: 100% 100%;
          width: 14px;
          height: 14px;
        }
      }
    }
    .disable_class {
      background-color: #7cd47c83;
    }
  }

  .f_20 {
    font-size: 20px;
  }

  .txt {
    color: #2b2b2b;
    font-size: 18px;

    &::v-deep p {
      font-size: 100%;
      margin-bottom: 20px;
    }
  }

  .bottom_select {
    text-align: center;

    .hint {
      margin: 0 auto;
      margin-top: 20px;
      width: 780px;
      padding: 5px;
      border: 1px dashed #2d9fd8;
      border-radius: 5px;
      background-color: #d9edf7;
    }

    .btn {
      display: inline-block;
      padding: 6px 12px;
      margin: 10px auto;
      color: #fff;
      border-radius: 4px;
      background-color: #f0ad4e;
      border-color: #eea236;
    }
  }
}
</style>