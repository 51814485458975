<template>
  <div class="article_container" :class="{ fixed_c: showSelect }">
    <m-nav
      ref="nav"
      :page="'Article'"
      v-if="hideNav"
      :detailUrl="objUrl"
    ></m-nav>
    <div class="container">
      <h2 class="headline">{{ content.novel_name }}</h2>
      <h3 class="chapter_name">{{ content.chapter_name }}</h3>
      <!-- 章节列表 -->
      <div class="chapter-nav">
        <div class="btn-group">
          <div
            class="prev"
            :class="{ disable_class: !content.prev_chapter_id }"
            @click="onPrev(content.prev_chapter_id)"
          >
            <img :src="img.cutL" />
          </div>
          <div
            class="list"
            :class="{ w_c: chapterShow }"
            @click="onChapterSelect"
          >
            <img :src="img.selectIcon" v-if="!chapterShow" />
            <div v-if="chapterShow" class="input_class">
              <h3 class="d_ellipsis">{{ searchType }}</h3>
            </div>
          </div>
          <div
            class="next"
            :class="{ disable_class: !content.next_chapter_id }"
            @click="onNext(content.next_chapter_id)"
          >
            <img :src="img.cutR" />
          </div>
        </div>
        <div class="hide_nav" @click="hideNav = !hideNav">
          <img :src="img.cutT" v-show="hideNav" />
          <img :src="img.cutB" v-show="!hideNav" />
        </div>
      </div>
      <div class="txt" v-html="content.content"></div>
      <!-- 章节列表 -->
      <div class="bottom_select">
        <div class="btn-group t_a">
          <div
            class="prev"
            :class="{ disable_class: !content.prev_chapter_id }"
            @click="onPrev(content.prev_chapter_id)"
          >
            <img :src="img.cutL" />
          </div>
          <div
            class="list"
            :class="{ w_c: chapterShow }"
            @click="onChapterSelect"
          >
            <img :src="img.selectIcon" v-if="!chapterShow" />
            <div v-if="chapterShow" class="input_class">
              <h3 class="d_ellipsis">{{ searchType }}</h3>
            </div>
          </div>
          <div
            class="next"
            :class="{ disable_class: !content.next_chapter_id }"
            @click="onNext(content.next_chapter_id)"
          >
            <img :src="img.cutR" />
          </div>
        </div>
        <!-- <div class="btn">Report chapter</div> -->
      </div>
    </div>
    <new-footer-m></new-footer-m>
    <!-- 弹窗 -->
    <div class="dialog_box" v-if="showSelect" @click="showSelect = false">
      <div class="new_dialog" @click.stop>
        <div
          class="dialog_item d_ellipsis"
          v-for="(item, index) in chapterList"
          :key="index"
          @click="onShowSelect(item.name, item.id)"
        >
          {{ item.name }}
          <div
            class="big_circle"
            :class="{ active_dialog: searchType == item.name }"
          >
            <div class="small_circle" v-if="searchType == item.name"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fictionChapterDetail, fictionChapterList } from "@/api/index";
import cutT from "@/assets/img/cutT.png";
import cutB from "@/assets/img/cutB.png";
import cutL from "@/assets/img/cutL.png";
import cutR from "@/assets/img/cutR.png";
import selectIcon from "@/assets/img/selectIcon.png";
import MNav from "@/components/M/MNav.vue";
import NewFooterM from "@/components/M/NewFooterM.vue";
export default {
  components: { MNav, NewFooterM },
  name: "Article",
  metaInfo() {
    return {
      title:
        this.content.novel_name +
        "-" +
        this.content.chapter_name +
        " | Webnovelsite",
      meta: [
        {
          name: "title",
          content:
            this.content.novel_name +
            "-" +
            this.content.chapter_name +
            " | Webnovelsite",
        },
        {
          name: "description",
          content: `Read free webnovel update - ${this.content.novel_name} - ${this.content.chapter_name} | Webnovelsite`,
        },
        {
          name: "keywords",
          content: "",
        },
      ],
    };
  },
  data() {
    return {
      hideNav: true, //   导航条显示隐藏
      img: {
        cutT: cutT,
        cutB: cutB,
        cutL: cutL,
        cutR: cutR,
        selectIcon: selectIcon,
      },
      content: { novel_name: "", chapter_name: "" }, //内容
      chapterList: [], //章节列表
      searchType: "", //选择类型
      showSelect: false, //选择框是否展开
      chapterShow: false, //是否显示章节选项
      nid: null, //小说id
      cid: null, //章节id
      objUrl: {},
    };
  },
  inject: ["reload"],
  created() {
    this.nid = this.$route.params.nid;
    this.cid = this.$route.params.cid;
    this.getChapterDetail();
  },
  methods: {
    // 获取章节内容
    getChapterDetail() {
      fictionChapterDetail(this.nid, this.cid).then((res) => {
        if (res.code == 200) {
          this.content = res.data;
          this.searchType = res.data.chapter_name;
          this.objUrl = {
            id: this.nid,
            name: res.data.novel_name,
            detailName: this.searchType,
          };
          this.localstoryList();
        }
      });
    },
    // 获取小说章节列表接口
    getFictionChapterList() {
      fictionChapterList(1, 10000, this.nid).then((res) => {
        if (res.code == 200) {
          this.chapterList = res.data.data;
        }
      });
    },
    // 选择框是否展开
    onShowSelect(name, id) {
      if (id == this.cid) {
        this.showSelect = false;
        return;
      }
      this.searchType = name;
      this.cid = id;
      this.showSelect = false;
      this.$router.push({
        path: `/article/${this.nid}/${this.cid}`,
      });
      this.reload();
      // this.getChapterDetail();
    },
    // 章节显示
    onChapterSelect() {
      if (!this.chapterShow) {
        this.chapterShow = true;
        this.getFictionChapterList();
        // let timer = setTimeout(function () {
        //   if (timer) {
        //     clearTimeout(this.timeHandle);
        //   }
        //   this.chapterShow = true;
        // }, 1000);
      } else {
        this.showSelect = !this.showSelect;
      }
    },
    // 去上一页
    onPrev(prevId) {
      if (prevId) {
        this.cid = prevId;
        this.$router.push(`/article/${this.nid}/${this.cid}`);
        this.reload();
      }
    },
    // 去下一页
    onNext(nextId) {
      if (nextId) {
        this.cid = nextId;
        this.$router.push(`/article/${this.nid}/${this.cid}`);
        this.reload();
      }
    },
    // 历史保存
    localstoryList() {
      let arrayLocal = JSON.parse(localStorage.getItem("historyList"));
      if (arrayLocal) {
        let isTrue = arrayLocal.some((item) => item.novel_id == this.nid);
        if (isTrue) {
          arrayLocal.forEach((item) => {
            if (item.novel_id == this.nid) {
              (item.last_chapter_name = this.content.chapter_name),
                (item.chapter_id = this.cid);
            }
          });
        } else {
          arrayLocal.unshift({
            novel_id: this.nid,
            novel_name: this.content.novel_name,
            last_chapter_name: this.content.chapter_name,
            chapter_id: this.cid,
          });
        }
      } else {
        arrayLocal = [];
        arrayLocal.unshift({
          novel_id: this.nid,
          novel_name: this.content.novel_name,
          last_chapter_name: this.content.chapter_name,
          chapter_id: this.cid,
        });
      }

      localStorage.setItem("historyList", JSON.stringify(arrayLocal));
    },
  },
};
</script>

<style lang="scss" scoped>
.fixed_c {
  position: fixed;
}
.article_container {
  font-size: 14px;
  .t_a {
    text-align: center;
  }

  .headline {
    color: #690;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 18px;
    margin-top: 5px;
    margin-bottom: 5px;
    text-align: center;
  }

  .chapter_name {
    font-size: 15px;
    text-align: center;
    color: #2b2b2b;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .txt {
    color: #2b2b2b;
    font-size: 14px;
    padding: 0 7.5px;

    &::v-deep p {
      font-size: 100%;
      margin-bottom: 20px;
    }
  }
  .d_ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .bottom_select {
    text-align: center;

    .btn {
      display: inline-block;
      padding: 6px 12px;
      margin: 10px auto;
      color: #fff;
      border-radius: 4px;
      background-color: #f0ad4e;
      border-color: #eea236;
    }
  }

  .chapter-nav {
    text-align: center;
    position: relative;
    margin-bottom: 20px;

    .hide_nav {
      height: 34px;
      position: absolute;
      left: 7.5px;
      top: 0;
      border-radius: 6px;
      padding: 6px 12px;
      background-color: #5cb85c;

      img {
        display: inline-block;
        vertical-align: middle;
        width: 18px;
        height: 18px;
      }
    }
  }

  .btn-group {
    color: #fff;
    display: flex;
    justify-content: center;

    .list,
    .prev,
    .next {
      height: 34px;
      padding: 6px 12px;
      background-color: #5cb85c;
      cursor: pointer;

      img {
        display: inline-block;
        vertical-align: middle;
        width: 14px;
        height: 14px;
      }
    }

    .prev {
      border-bottom-left-radius: 4px;
      border-top-left-radius: 4px;
    }

    .list {
      margin: 0 5px;

      img {
        width: 18px;
        height: 18px;
      }

      .input_class {
        position: relative;

        &::after {
          position: absolute;
          right: -10px;
          top: 50%;
          transform: translate(0, -50%);
          content: " ";
          background: url("~@/assets/img/cutB.png") center center no-repeat;
          background-size: 100% 100%;
          width: 8px;
          height: 8px;
        }
      }
    }
    .w_c {
      width: 145px;
    }
    .disable_class {
      background-color: #7cd47c83;
    }

    .next {
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px;
    }
  }

  .dialog_box {
    position: fixed;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000000a3;

    .new_dialog {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      height: 80%;
      width: 80%;
      background: #fff;
      overflow-y: auto;

      .dialog_item {
        padding: 0 40px 0 15px;
        height: 40px;
        line-height: 40px;
        border-bottom: 1px solid #e7e7e7;
        position: relative;
        .big_circle {
          width: 16px;
          height: 16px;
          border-radius: 50%;
          border: 1px solid #c4c5c9;
          position: absolute;
          top: 50%;
          right: 20px;
          transform: translate(0, -50%);

          .small_circle {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #445889;
          }
        }

        .active_dialog {
          border-color: #445889;
        }
      }
    }
  }
}
</style>