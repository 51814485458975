<template>
  <div class="select-container">
    <h3 class="input_class">{{ search }}</h3>
    <div class="select-content" :class="{ active: showSelect }">
      <div
        v-for="(item, index) in list"
        class="select-line"
        :class="{ bgc_b: search == item.name }"
        @click="onSelect(item.name, item.id)"
        :key="index"
      >
        {{ item.name }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ChapterSelect",
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    search: {
      type: String,
      default: "",
    },
    showSelect: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onSelect(value, id) {
      this.$emit("changes", value, id);
    },
  },
};
</script>
<style lang="scss" scoped>
.select-container {
  position: relative;
  font-size: 14px;
  text-align: left;
  height: 100%;
  .input_class {
    width: 100%;
    position: relative;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .select-content {
    position: absolute;
    left: -12px;
    top: 28px;
    max-height: 400px;
    // width: auto;
    overflow-y: auto;
    display: none;
    border: 1px solid #cccccc;
    box-shadow: 0px 0px 10px -1px rgba(0, 0, 0, 0.2);
    background-color: #5cb85c;
    z-index: 3;
    &.active {
      display: block;
      // animation: selectanim 0.25s;
      // -webkit-animation: selectanim 0.25s;
    }
    .bgc_b {
      background-color: #82b9d1a4;
      color: #fff;
    }

    .select-line {
      padding: 2px 5px;
      padding-left: 20px;
      cursor: default;
      white-space: nowrap;
      display: block;
    }

    @keyframes selectanim {
      from {
        opacity: 0;
        top: 80px;
      }

      to {
        opacity: 1;
        top: 40px;
      }
    }

    @-webkit-keyframes selectanim {
      from {
        opacity: 0;
        top: 80px;
      }

      to {
        opacity: 1;
        top: 40px;
      }
    }
  }
}
</style>